import { SessionProvider } from 'next-auth/react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_KEY } from '../envs'
const stripePromise = loadStripe(STRIPE_KEY);

function MyApp({ Component, pageProps, session }) {
    return (
        <>
            <SessionProvider session={session}>
                <Elements stripe={stripePromise}>
                    <Component {...pageProps}/>
                </Elements>
            </SessionProvider>
            <style jsx global>{`
                html,
                body {
                padding: 0;
                margin: 0;
                font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
                    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                    sans-serif;
                    -webkit-user-select:none;
                    height: 100%;
                }

                #__next {
                    height: 100%;
                }

                * {
                box-sizing: border-box;
                }

                @media (max-width: 600px) {
                    .main { 
                        height: 100% !important;
                    }
                    .bottom { 
                        height: 100% !important;
                    }
                    .rightMainN { 
                        height: 100% !important;
                    }
                }
            `}</style>
        </>
    )
}
export default MyApp;